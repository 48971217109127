<template>
  <div class="">
    <div class="d-flex flex-column align-center mt-4">
      <v-icon large>mdi-check-outline</v-icon>
    </div>

    <div class="d-flex flex-column align-center mt-6">
      <div class="text-h6">Password reset</div>
      <div class="text-center">Your password has been successfully reset.</div>
    </div>

    <div class="mt-10">
      <v-btn
        @click="navigateToLoginRoute"
        color="primary"
        class="rounded-lg mt-4 font-weight-bold"
        height="48px"
        block
      >
        <v-icon left>mdi-arrow-left</v-icon>
        Back to log in
      </v-btn>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },

  methods: {
    navigateToLoginRoute() {
      this.$router.push({ name: "login" });
    },
  },
};
</script>